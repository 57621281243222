import React from 'react';
import BaseLayout from '../../components/templates/BaseLayout';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Button from '../../components/atoms/inputs/Button';
import HorizontalSlider from '../../components/molecules/HorizontalSlider';
import SEO from '../../components/atoms/utility/seo';
import BusinessApplicant from '../../components/organisms/BusinessApplicant';

const BusinessPage = ({ data }) => {


  const handleCTAButtonClick = () => {
    // window?.gtag('event', 'join_venue_network_click', {
    //   event_category: 'CTA',
    //   event_label: 'Join Venue Network',
    // });
    window.location.href = 'https://community.flockx.io/flock-detail/109cddaf-8fe4-4bf0-9eae-6860ac8cc07d?previousTab=flock';
  };

  return (
    <BaseLayout
    mainSectionClass="z-20 relative flex-grow h-full w-full"
    hideHeroImage
    customHeaderClass=" "
  >
    <SEO
        title="Expand your reach, Join the ArtPrize Venue Network Today"
        keywords={[
          'Business AI Agent management',
          'Venue owners',
          'ai agent',
          'agentic',
          'multi agent',
          'knowledge base',
          'flockx',
        ]}
        description={
          'Join the Flockx ecosystem to expand enhance your business with your own AI Agent. Unlock autonomous collaboration to give your business superpowers today.'
        }
      />


<div className="relative isolate bg-cover bg-center min-h-screen pb-2 flex items-end lg:pb-20" style={{ backgroundImage: "url('https://res.cloudinary.com/fetch-ai/image/upload/v1725380768/flockx-website/venue-owners-artprize/Painting-with-avatar-002c-expanded_edvmpn.jpg')" }}>
  <svg
    className="absolute inset-0 -z-10 h-full w-full"
    aria-hidden="true"
  >
    <defs>
      <linearGradient id="gradient" x1="0%" y1="100%" x2="0%" y2="0%">
        <stop offset="0%" style={{ stopColor: "black", stopOpacity: 1 }} />
        <stop offset="100%" style={{ stopColor: "transparent", stopOpacity: 1 }} />
      </linearGradient>
    </defs>
    <rect width="100%" height="100%" strokeWidth={0} fill="url(#gradient)" />
  </svg>
  
  <div className="relative mx-auto flex pb-6 px-3 lg:pb-10 lg:gap-x-10">
  <div className="flex-shrink-0 flex-grow pt-4 hidden lg:block">
           <img
              className="h-60 rounded-2xl mx-auto"
              src="https://res.cloudinary.com/fetch-ai/image/upload/v1725367466/flockx-website/venue-owners-artprize/qr-venue-owner-artprize_qfwtud.jpg"
              alt="Venue owners QR Code to join flock"
            />
    </div>

    <div className="mx-auto text-center lg:text-left lg:mx-0 lg:flex-auto">
      <h1 className="text-3xl font-bold tracking-regular text-white pb-2 !leading-[normal] lg:text-7xl">
      Join the ArtPrize <br/> <span className='text-highlight italic'>Venue Network </span>today
      </h1>
      <p className="hidden lg:block text-lg !leading-[normal] text-white lg:text-2xl lg:max-w-3xl">
      Scan here to join the ArtPrize Venues Flock, and unite with fellow venues, access Flockx support & get the most out of ArtPrize.
      </p>

      <p className="block lg:hidden text-lg !leading-[normal] text-white max-w-lg lg:text-3xl">
      Join the ArtPrize Venues Flock to connect with fellow venues and access Flockx support.
      </p>


        <div className="lg:hidden flex flex-row pt-4 items-center justify-center">
        <Button
              label="Join Venue Network"
              onClickHandler={handleCTAButtonClick}
              id="Join Venue Network"
              className="px-10 border-0"
            />
        </div>
     </div>
  </div>
</div>


{/* FAQ section */}

<section className='container mx-auto px-4 pt-10 pb-12 lg:px-20 lg:pt-40'>

<h2 className="text-gray-500 font-bold text-3xl pb-2 lg:text-6xl lg:pb-10 text-center !leading-[normal]">
Venue Owners FAQs
</h2>
<div className="relative h-auto w-full mx-auto pb-4 lg:w-4/5 lg:pb-10">
  <img
    className="h-full w-full object-contain rounded-md mx-auto"
    src="https://res.cloudinary.com/fetch-ai/image/upload/v1725367694/flockx-website/venue-owners-artprize/venue-owners-banner_thxiiv.png"
    alt="Flockx Venue Owners App Mockups"
  />
</div>    


<div>
  <h3 className="text-gray-500 font-bold text-lg lg:text-2xl !leading-[normal]">Where can I find my business profile?</h3>
  <p className="text-gray-500 text-md lg:text-lg !leading-[normal]">Your business profile has already been created. To view your venue, follow these steps:</p>
  <ul className="list-disc list-inside text-gray-500 text-md lg:text-lg !leading-[normal]">
    <li>Find the ArtPrize Community: click on the Flockx logo in the navigation bar, type in ArtPrize and click on the card</li>
    <li>Find your venue: in the venue section, click on view all and you can type your venue name into the search bar and click on the card.</li>
  </ul>
</div>


<div>
  <h3 className="text-gray-500 font-bold text-lg lg:text-2xl pt-5 !leading-[normal]">How can I edit my business profile details or my business profile image?</h3>
  <p className="text-gray-500 text-md lg:text-lg !leading-[normal]">To edit your business profile page, follow these steps:</p>
  <ul className="list-disc list-inside text-gray-500 text-md lg:text-lg !leading-[normal]">
    <li>Access Your Profile: Tap on your profile icon located in the bottom navigation bar.</li>
    <li>Start a Chat with Your Sidekick: Tap on your sidekick to open the chat.</li>
    <li>Send Your Request: Share the changes you want to make with your sidekick. Our team will review your request and update the information on the Flockx platform.</li>
    <li>Processing Time: Please note that updates are not immediate; our team typically handles these requests within 24-48 hours.</li>
  </ul>
</div>

<div>
  <h3 className="text-gray-500 font-bold text-lg lg:text-2xl pt-5 !leading-[normal]">How can I add, edit, and remove events to my business?</h3>
  <p className="text-gray-500 text-md lg:text-lg !leading-[normal]">To add events to your business, follow these steps:</p>
  <ul className="list-disc list-inside text-gray-500 text-md lg:text-lg !leading-[normal]">
    <li>Open Your Profile: Tap on your profile icon in the bottom navigation bar.</li>
    <li>Chat with Your Sidekick: Tap on your sidekick to start a chat.</li>
    <li>Request to Add an Event: Let your sidekick know that you’d like to add an event. Your sidekick will ask for details such as the event name, description, images, start/end dates, and times.</li>
    <li>Processing Time: Once you provide the information, our team will review and add the event to the platform. Please allow 24-48 hours for the event to be posted.</li>
  </ul>
</div>

<div>
  <h3 className="text-gray-500 font-bold text-lg lg:text-2xl pt-5 !leading-[normal]">Do I need to claim my business to make changes?</h3>
  <p className="text-gray-500 text-md lg:text-lg !leading-[normal]">No, the email addresses you have provided to ArtPrize for your Venue Managers have been linked with your venue.</p>
</div>


<div>
  <h3 className="text-gray-500 font-bold text-lg lg:text-2xl pt-5 !leading-[normal]">How can I get in touch with flockx?</h3>
  <p className="text-gray-500 text-md lg:text-lg !leading-[normal]">There are several ways to get in touch with FlockX:</p>
  <ul className="list-disc list-inside text-gray-500 text-md lg:text-lg !leading-[normal]">
    <li>Email: You can reach us at <a href="mailto:contact@flockx.io" className='text-highlight'>contact@flockx.io</a></li>
    <li>Website Chat: Visit our website where you can use Intercom to chat directly with our team.</li>
    <li>In-App Contact: If you're using the FlockX app, you can reach out to us through your profile settings page. Look for the 'Leave Feedback' or 'Support' buttons to get in touch.</li>
  </ul>
</div>


</section>



    </BaseLayout>
  );
};



export default BusinessPage;
